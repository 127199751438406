import React, { useEffect, useState } from 'react';
import { IDocument } from 'src/types/strapi/documents';

import Arrow from '../../images/document-hover-arrow.inline.svg';
import Substrate from '../../images/document-substrate.inline.svg';
import { mediaUrl } from '../../utils';

import './index.css';

interface IProps {
    document: IDocument
}

const DocumentCard = ({ document }: IProps) => {

    const [currentUrl, setCurrentUrl] = useState<string>('');

    useEffect(() => {
        if (document.url?.length) {
            setCurrentUrl(document.url);
            return;
        }

        setCurrentUrl(document.file.url);
    }, [])

    return (
        <a href={mediaUrl(currentUrl)} target="_blank" className="document-card">
            <div className="document-card__wrapper">
                <p className="document-card__title" dangerouslySetInnerHTML={{ __html: document.title }} />
                <p className="document-card__type" dangerouslySetInnerHTML={{ __html: document.type }} />
            </div>
            <Arrow className="document-card__arrow" />
            <Substrate className="document-card__substrate" />
        </a>
    );
};

export default DocumentCard;
